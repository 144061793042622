<template>
  <div>
    <el-tabs>
      <el-tab-pane label="基础信息">
        <el-card style="min-height: 45rem">
          <el-form label-position="left">
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="商家分类" class="flex-def">
                  <CateChoose v-model="form.cate_id"></CateChoose>
                </el-form-item>
                <el-form-item label="商家管理员" class="flex-def">
                  <ManageChoose v-model="form.manage_uid"></ManageChoose>
                </el-form-item>
                <el-form-item label="商家名称" class="flex-def">
                  <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="缩略图" class="flex-def">
                  <y_upload_img v-model="form.pic"></y_upload_img>
                </el-form-item>
                <el-form-item label="营业执照" class="flex-def">
                  <y_upload_img v-model="form.business_license"></y_upload_img>
                </el-form-item>
                <el-form-item label="营业时间" class="flex-def">
                  <el-input v-model="form.business_hours"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" class="flex-def">
                  <el-input v-model="form.link_phone"></el-input>
                </el-form-item>
                <el-form-item label="联系二维码" class="flex-def">
                  <y_upload_img v-model="form.link_qrcode"></y_upload_img>
                </el-form-item>
                <el-form-item label="承诺视频" class="flex-def">
                  <y_upload_video v-model="form.promise_video"></y_upload_video>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所在区域" class="flex-def">
                  <AreaChoose :model-val="form" @change="chooseArea"></AreaChoose>
                </el-form-item>
                <el-form-item label="详细地址" class="flex-def">
                  <el-input v-model="form.area_detail" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="地标信息" class="flex-def">
                  <el-input v-model="form.landmark" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="坐标">
                  {{form.pos_lat}},{{form.pos_long}}
                  <add @point="point" :lat="form.pos_lat" :long="form.pos_long"></add>
                </el-form-item>
                <el-form-item align="right">
                  <el-button @click="edit" type="primary">保存</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="门头照片">
        <el-card>
          <BannerEdit @save="edit" v-model="form.banner"></BannerEdit>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import CateChoose from "@/components/shop/plugin/privilegeCard/Merchant/CateChoose";
import ManageChoose from "@/components/shop/plugin/privilegeCard/Merchant/ManageChoose";
import add from "@/components/qqmap/add";
import AreaChoose from "@/components/shop/plugin/privilegeCard/Merchant/AreaChoose";
import BannerEdit from "@/components/shop/plugin/privilegeCard/Merchant/BannerEdit";
import y_upload_video from "@/components/y-form/y-upload/y_upload_video";
export default {
  name: "edit",
  components: {
    y_upload_video,
    BannerEdit,
    AreaChoose,
    ManageChoose,
    CateChoose,
    y_upload_img,
    add,
  },
  data() {
    return {
      form: {
        cate_id: 0,
        manage_uid: 0,
        agent_id: 0,
        title: "",
        pic: "",
        banner: "",
        business_license: "",
        business_hours: "",
        link_phone: "",
        area_p_code:"",
        area_p_label:"",
        area_c_code:"",
        area_c_label:"",
        area_d_code:"",
        area_d_label:"",
        area_s_code:"",
        area_s_label:"",
        area_detail:"",

        pos_lat:"",
        pos_long:"",
        landmark:"",
        status:0,
        register_type:0,

        link_qrcode:"",

        promise_video:"",
      },
      edit_id:0,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  computed:{

  },
  methods: {
    chooseArea(e){
      this.form.area_p_label = e[0].label;
      this.form.area_p_code = e[0].value;
      this.form.area_c_label = e[1].label;
      this.form.area_c_code = e[1].value;
      this.form.area_d_label = e[2].label;
      this.form.area_d_code = e[2].value;
      this.form.area_s_label = e[3].label;
      this.form.area_s_code = e[3].value;
    },
    point(lat,lng){
      this.form.pos_lat = lat + "";
      this.form.pos_long = lng + "";
    },
    load() {
      this.$u.api.shop.plugin.privilegeCard.merchantOne({id:this.edit_id}).then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.shop.plugin.privilegeCard.merchantEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
  }
}
</script>

<style scoped>

</style>