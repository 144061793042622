<template>
  <div>
    <el-cascader placeholder="请选择行业分类" style="width: 100%"
                 v-model="cate_choose"
                 :options="opt"
                 :props="{value:'id',label:'title'}"></el-cascader>
  </div>
</template>

<script>
export default {
  name: "CateChoose",
  model:{
    prop:"modelVal",
    event:"change"
  },
  props:{
    modelVal:{
      type:Number,
      default:0,
    }
  },
  watch:{
    modelVal(){
      this.cate_choose = [this.modelVal];
    },
    cate_choose(){
      this.$emit("change",this.cate_choose[0]);
    }
  },
  data() {
    return {
      opt: [],
      cate_choose: [this.modelVal],
    }
  },
  mounted() {
    this.$u.api.shop.plugin.privilegeCard.merchantCateAll().then(res => {
      this.opt = res;
    })
  }
}
</script>

<style scoped>

</style>